.teaser-list-b {
}

.teaser-list-b__list {
	display: grid;
	row-gap: var(--space-5--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(3, minmax(0, 1fr));
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.teaser-list-b__list {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-list-b__list {
		row-gap: var(--space-10--rem);
		column-gap: var(--space-10--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.teaser-list-b__list {
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
}

.teaser-list-b__list:not(:first-child) {
	margin-block-start: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-list-b__list:not(:first-child) {
		margin-block-start: var(--space-30--rem);
	}
}

.teaser-list-b__list-item {
	min-width: 0;
}
