:root {
	color-scheme: light;
	scroll-behavior: smooth;
}

:root[data-has-scroll-snap] {
	scroll-snap-type: y mandatory;
}

body {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	caption-side: bottom;
	margin-inline: 0;
	margin-block: var(--space-30--rem);
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-block-start: 0;
}

table:last-child {
	margin-block-end: 0;
}

.mr-table-scroll-container {
	overflow-x: auto;
}

.mr-table-scroll-container table {
	max-width: none;
}

th,
td {
	box-sizing: border-box;
	padding-inline: var(--space-30--px);
	padding-block: var(--space-30--rem);
	text-align: left;
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	margin: 0;
	margin-block-end: 2rem;
	padding-inline-start: 1.25rem;
}

:is(ol, ul):not([class]):last-child {
	margin-block-end: 0;
}

li :is(ol, ul):not([class]) {
	margin-block-end: 0;
	padding-block-start: 0.25rem;
}

li:not([class]) {
	line-height: 1;
	margin: 0;
	padding: 0 0 0.25rem 0;
	position: relative;
}

/* #region Shared link styles (WYSIWYG and components) */
:any-link {
	color: currentColor;
	text-decoration: underline;
	transition: color 96ms linear;
}

:any-link:hover {
	color: var(--color-inline-link-hover);
}
/* #endregion Shared link styles (WYSIWYG and components) */

/* #region WYSIWYG link styles */
:any-link:not([class]) {
	color: var(--color-inline-link);
	display: inline;
}

:any-link:not([class]):hover {
	color: var(--color-inline-link-hover);
}

p a:not([class]) {
	line-height: inherit;
}
/* #endregion WYSIWYG link styles */

mr-video {
	display: block;
}

[id]:target {
	scroll-margin-top: 60px;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	[id]:target {
		scroll-margin-top: 80px;
	}
}
