.site-header-and-main-content {
	min-height: 100vh;
	min-height: 100svh;
}

[data-header-overlaps-content] .site-header-and-main-content {
	display: grid;
}

[data-header-overlaps-content] .site-header-and-main-content > * {
	grid-row: 1;
	grid-column: 1;
	min-width: 0;
	min-height: 0;
}

[data-header-overlaps-content] .site-header-and-main-content > .site-header-container {
	height: min-content;
}
