.about-items {
}

.about-item {
	background-color: var(--color-background);
	display: grid;
	height: 100vh;
	height: 100svh;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.about-item {
		height: auto;
	}
}

.about-item[id]:target {
	scroll-margin-top: 0;
}

:root[data-has-scroll-snap] .about-item {
	scroll-snap-align: start;
}

.about-item__content-container {
	grid-row: 1;
	grid-column: 1;
	min-width: 0;
	z-index: 2;
}

.about-item__sticky-container {
	padding-block: var(--space-10--rem);
	position: sticky;
	top: calc(50vh - 8rem);
	top: calc(50vh - 8lh);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.about-item__sticky-container {
		top: calc(50vh - 2rem);
		top: calc(50vh - 2lh);
	}
}

.about-item__content {
	color: var(--color-foreground);
	display: grid;
	row-gap: var(--space-10--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(6, minmax(0, 1fr));
	width: 100%;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.about-item__content {
		grid-template-columns: repeat(18, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.about-item__content {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.about-item__content {
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
}

.about-item__counter {
	font-variant-numeric: tabular-nums;
	grid-column: 1 / span 1;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.about-item__counter {
		grid-column: 1 / span 2;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.about-item__counter {
		grid-column: 1 / span 1;
	}
}

.about-item__title {
	grid-column: 2 / span 5;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.about-item__title {
		grid-column: 3 / span 16;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.about-item__title {
		grid-column: 2 / span 5;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.about-item__title {
		grid-column: 2 / span 2;
	}
}

.about-item__text {
	grid-column: 1 / span 6;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.about-item__text {
		grid-column: 1 / span 18;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.about-item__text {
		grid-column: 1 / span 6;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.about-item__text {
		grid-column: 4 / span 6;
	}
}

.about-item__text > :first-child {
	margin-block-start: 0;
}

.about-item__text > :last-child {
	margin-block-end: 0;
}

.about-item__image-container {
	grid-row: 1;
	grid-column: 1;
	min-width: 0;
	z-index: 1;
}

.about-item__image {
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: cover) {
	.about-item__image {
		object-fit: cover;
		width: 100%;
		height: 100vh;
		height: 100svh;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		.about-item__image {
			height: auto;
		}
	}
}
