.image-33-container {
	margin: 0;
}

.image-33 {
	border-radius: 15px;
	width: 100%;
	height: auto;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.image-33 {
		border-radius: 20px;
	}
}

.image-33-caption {
	margin-block-start: var(--space-10--rem);
}
