.link {
	-mrh-resets: button;
	color: var(--color-inline-link);
	text-decoration: 2px underline currentColor;
	transition: color 96ms linear;
}

.link:hover {
	color: var(--color-inline-link-hover);
}
