.artist-information-desktop {
	display: none;
	row-gap: var(--space-10--rem);
	column-gap: var(--space-10--px);
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.artist-information-desktop {
		display: grid;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.artist-information-desktop {
		row-gap: var(--space-20--rem);
	}
}

:is(.artist-information-desktop__title, .artist-information-desktop__city, .artist-information-desktop__job-title, .artist-information-desktop__extra-label, .artist-information-desktop__biography) > :first-child {
	margin-block-start: 0;
}

:is(.artist-information-desktop__title, .artist-information-desktop__city, .artist-information-desktop__job-title, .artist-information-desktop__extra-label, .artist-information-desktop__biography) > :last-child {
	margin-block-end: 0;
}

.artist-information-desktop__title-and-job-title-and-city {
	display: grid;
	column-gap: var(--space-10--px);
	grid-column: 1 / span 3;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	min-width: 0;
}

.artist-information-desktop__extra-label-and-links-and-portrait {
	grid-column: 2 / span 1;
	min-width: 0;
}

.artist-information-desktop__biography {
	grid-column: 3 / span 1;
	min-width: 0;
}

.artist-information-desktop__title,
.artist-information-desktop__city,
.artist-information-desktop__job-title {
	grid-column: span 1;
	min-width: 0;
}

.artist-information-desktop__links:not(:first-child) {
	margin-block-start: var(--space-10--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.artist-information-desktop__links:not(:first-child) {
		margin-block-start: var(--space-20--rem);
	}
}

.artist-information-desktop__portrait:not(:first-child) {
	margin-block-start: var(--space-10--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.artist-information-desktop__portrait:not(:first-child) {
		margin-block-start: var(--space-20--rem);
	}
}

.artist-information-desktop__portrait__image {
	border-radius: 20px;
	width: 125px;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.artist-information-desktop__portrait__image {
		width: 150px;
	}
}
