* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-weight: 650;
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	body {
		hyphens: auto;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	body {
		font-size: 0.875rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	body {
		font-size: 1.125rem;
	}
}

:is(h1, h2, h3, h4, h5, h6) {
	margin: 0;
}

:is(h1, h2, h3, h4, h5, h6) + p {
	margin-block-start: 0;
}

.type-a,
.type-b,
.type-c,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-sans);
	font-style: normal;
	font-weight: 650;
}

.type-a,
h1 {
	font-size: 1.3125rem;
	letter-spacing: -1.05px;
	line-height: 1;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-a,
	h1 {
		font-size: 1.875rem;
		letter-spacing: -1.5px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-a,
	h1 {
		font-size: 3.125rem;
		letter-spacing: -2.5px;
	}
}

.type-b,
h2,
blockquote,
blockquote p {
	font-size: 1.125rem;
	letter-spacing: -0.9px;
	line-height: 1;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-b,
	h2,
	blockquote,
	blockquote p {
		font-size: 1.5rem;
		letter-spacing: -1.2px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-b,
	h2,
	blockquote,
	blockquote p {
		font-size: 1.875rem;
		letter-spacing: -1.5px;
	}
}

.type-c,
h3 {
	font-size: 0.9375rem;
	letter-spacing: -0.75px;
	line-height: 1;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-c,
	h3 {
		font-size: 1.125rem;
		letter-spacing: -0.9px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-c,
	h3 {
		font-size: 1.4375rem;
		letter-spacing: -1.15px;
	}
}

.type-d,
p,
h4,
h5,
h6 {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 650;
	letter-spacing: -0.6px;
	line-height: 1;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-d,
	p,
	h4,
	h5,
	h6 {
		font-size: 0.875rem;
		letter-spacing: -0.7px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-d,
	p,
	h4,
	h5,
	h6 {
		font-size: 1.125rem;
		letter-spacing: -0.8px;
	}
}

u,
strong,
b,
i,
em {
	font-style: normal;
	font-weight: 650;
}

p,
blockquote {
	margin-block: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	p,
	blockquote {
		margin-block: var(--space-30--rem);
	}
}

blockquote {
	border-inline-start: 4px solid currentColor;
	margin-inline-start: 0;
	padding-inline-start: 20px;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	blockquote {
		padding-inline-start: 30px;
	}
}
