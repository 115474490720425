.row {
	margin-top: var(--space-100--rem);
	margin-bottom: var(--space-100--rem);
	position: relative;
}

[data-has-small-row-gaps] .row {
	margin-top: var(--space-30--rem);
	margin-bottom: var(--space-30--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	[data-has-small-row-gaps] .row {
		margin-top: var(--space-50--rem);
		margin-bottom: var(--space-50--rem);
	}
}

:root[data-has-scroll-snap] .row {
	scroll-snap-align: center;
	scroll-snap-stop: always;
}
