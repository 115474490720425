.site-footer-container {
}

[data-footer-is-100vh] .site-footer-container {
	box-sizing: border-box;
	display: grid;
	align-content: end;
	min-height: 100vh;
	min-height: 100svh;
}

:root[data-has-scroll-snap] [data-footer-is-100vh] .site-footer-container {
	scroll-snap-align: center;
}

.site-footer-container > .wrapper {
	min-width: 0;
}

.site-footer {
	color: var(--color-foreground);
	padding-block: var(--space-5--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer {
		padding-block: var(--space-10--rem);
	}
}

.site-footer__home-link {
	display: block;
	margin: 0;
	padding: 0;
	text-decoration: none;
}

.site-footer__home-link:hover {
	color: currentColor;
}

.site-footer__title-and-date-and-location {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(6, minmax(0, 1fr));
	margin-block-end: 0.125rem;
	width: 100%;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-footer__title-and-date-and-location {
		grid-template-columns: repeat(18, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__title-and-date-and-location {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(6, minmax(0, 1fr));
		margin-block-end: var(--space-5--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__title-and-date-and-location {
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__title-and-date-and-location {
		margin-block-end: var(--space-10--rem);
	}
}

.site-footer__title {
	display: none;
	grid-row: 1;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-footer__title {
		display: block;
		grid-column: 1 / span 6;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__title {
		grid-column: 1 / span 2;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__title {
		grid-column: 1 / span 3;
	}
}

.site-footer__date {
	grid-row: 1;
	grid-column: 1 / span 6;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-footer__date {
		grid-column: 7 / span 12;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__date {
		grid-column: 3 / span 4;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__date {
		grid-column: 4 / span 6;
	}
}

.site-footer__location {
	grid-row: 1;
	grid-column: 1 / span 6;
	text-align: right;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-footer__location {
		grid-column: 7 / span 12;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__location {
		grid-column: 3 / span 4;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__location {
		grid-column: 4 / span 6;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 23rem) {
	.site-footer__date [data-date-format="short"] {
		display: none;
	}
}

.site-footer__date [data-date-format="default"] {
	display: none;
}

/* from-xsmall,min-width-560px */
@media (width >= 23rem) {
	.site-footer__date [data-date-format="default"] {
		display: inline;
	}
}

.site-footer__logo {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(3, minmax(0, 1fr));
	min-width: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__logo {
		column-gap: var(--space-10--px);
	}
}

.site-footer__logo svg {
	width: 100%;
	min-width: 0;
}

.site-footer__navigation-lists {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(3, minmax(0, 1fr));
	margin-block-start: var(--space-5--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__navigation-lists {
		column-gap: var(--space-10--px);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__navigation-lists {
		margin-block-start: var(--space-10--rem);
	}
}

.site-footer__navigation__list {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
	min-width: 0;
}

.site-footer__navigation__item {
	line-height: 0;
}

.site-footer__navigation__item__link {
	color: var(--color-inline-link);
	text-decoration: none;
	text-decoration: underline transparent;
	transition:
		color 96ms linear,
		text-decoration-color 96ms linear;
}

.site-footer__navigation__item__link:hover {
	color: var(--color-inline-link-hover);
}

[data-is-active] .site-footer__navigation__item__link {
	color: var(--color-inline-link-current);
	text-decoration-color: currentColor;
}
