.accordion-partners {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
}

.accordion-partners__heading {
	margin: 0;
	padding: 0;
}

.accordion-partners__trigger {
	-mrh-resets: button;
	color: var(--color-accordion);
	display: block;
	text-align: left;
	transition: color 96ms linear;
	width: 100%;
}

html:not(.no-js) .accordion-partners__trigger:hover {
	color: var(--color-accordion-hover);
	cursor: pointer;
}

.accordion-partners__item:not(:last-child) {
	margin-block-end: 2px;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.accordion-partners__item:not(:last-child) {
		margin-block-end: 0;
	}
}

.accordion-partners__item__title {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(6, minmax(0, 1fr));
	width: 100%;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion-partners__item__title {
		grid-template-columns: repeat(18, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-partners__item__title {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-partners__item__title {
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
}

html.no-js .accordion-partners__item__title {
	cursor: text;
	pointer-events: auto;
	user-select: text;
}

.accordion-partners__item__title__counter {
	font-variant-numeric: tabular-nums;
	grid-column: 1 / span 1;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion-partners__item__title__counter {
		grid-column: 1 / span 2;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-partners__item__title__counter {
		grid-column: 1 / span 1;
	}
}

.accordion-partners__item__title__text-and-excerpt {
	display: grid;
	column-gap: var(--space-5--px);
	grid-column: 2 / span 5;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion-partners__item__title__text-and-excerpt {
		grid-column: 3 / span 16;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-partners__item__title__text-and-excerpt {
		column-gap: var(--space-10--px);
		grid-column: 2 / span 5;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-partners__item__title__text-and-excerpt {
		grid-column: 2 / span 8;
		grid-template-columns: repeat(8, minmax(0, 1fr));
	}
}

.accordion-partners__item__title__text {
	grid-column: 1 / span 1;
	min-width: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-partners__item__title__text {
		grid-column: 1 / span 2;
	}
}

.accordion-partners__item__title__excerpt {
	grid-column: 1 / span 1;
	min-width: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-partners__item__title__excerpt {
		grid-column: 3 / span 6;
	}
}

.accordion-partners__panel {
	padding-block-start: var(--space-5--rem);
	padding-block-end: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.accordion-partners__panel {
		padding-block-end: var(--space-30--rem);
	}
}

html:not(.no-js, .js-loading-error) .accordion-partners__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion-partners__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.accordion-partners__panel__inner {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(1, minmax(0, 1fr));
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-partners__panel__inner {
		column-gap: var(--space-10--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-partners__panel__inner {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.accordion-partners__panel__inner__image {
	border-radius: 15px;
	grid-column: 1 / span 1;
	width: 100%;
	min-width: 0;
	height: auto;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-partners__panel__inner__image {
		border-radius: 20px;
	}
}

.accordion-partners__panel__inner__text {
	grid-column: 1 / span 1;
	min-width: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-partners__panel__inner__text {
		grid-column: 2 / span 2;
	}
}

.accordion-partners__panel__inner__text:not(:first-child) {
	margin-block-start: var(--space-10--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-partners__panel__inner__text:not(:first-child) {
		margin-block-start: 0;
	}
}

.accordion-partners__panel__inner__text > :first-child {
	margin-block-start: 0;
}

.accordion-partners__panel__inner__text > :last-child {
	margin-block-end: 0;
}
