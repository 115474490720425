@import url("information/mobile.css");
@import url("information/desktop.css");

.artist-information {
	background-color: var(--color-artist-info-background);
	color: var(--color-artist-info-foreground);
	padding-block: var(--space-10--rem);
}

.artist-information :any-link:hover {
	color: var(--color-artist-info-link-hover);
}
