.tickets-list {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.tickets-list__item-container {
	border-top: 0.0625rem solid var(--color-list-item-border);
	margin-block-end: var(--space-10--rem);
}

.tickets-list__item-container:last-child {
	margin-block-end: 0;
}

.tickets-list__item {
	display: grid;
	row-gap: var(--space-5--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(6, minmax(0, 1fr));
	padding: 0;
	padding-block-start: var(--space-10--rem);
	padding-block-end: var(--space-20--rem);
}

.tickets-list__item[data-is-strike-through] {
	text-decoration: line-through;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.tickets-list__item {
		grid-template-columns: repeat(18, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.tickets-list__item {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(6, minmax(0, 1fr));
		padding-block-end: var(--space-30--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.tickets-list__item {
		row-gap: 0;
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.tickets-list__item {
		padding-block-end: var(--space-50--rem);
	}
}

.tickets-list__item__title {
	grid-column: 1 / span 3;
	margin: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.tickets-list__item__title {
		grid-column: 1 / span 9;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.tickets-list__item__title {
		grid-column: 1 / span 3;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.tickets-list__item__title {
		grid-column: 1 / span 2;
	}
}

.tickets-list__item__price {
	grid-column: 4 / span 3;
	margin: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.tickets-list__item__price {
		grid-column: 10 / span 9;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.tickets-list__item__price {
		grid-column: 4 / span 3;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.tickets-list__item__price {
		grid-column: 3 / span 1;
	}
}

.tickets-list__item__text {
	grid-column: 1 / span 6;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.tickets-list__item__text {
		grid-column: 1 / span 18;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.tickets-list__item__text {
		grid-column: 1 / span 6;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.tickets-list__item__text {
		grid-column: 4 / span 3;
	}
}

.tickets-list__item__text > :first-child {
	margin-block-start: 0;
}

.tickets-list__item__text > :last-child {
	margin-block-end: 0;
}

.tickets-list__item__cta {
	grid-column: 1 / span 6;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.tickets-list__item__cta {
		grid-column: 1 / span 18;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.tickets-list__item__cta {
		grid-column: 1 / span 6;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.tickets-list__item__cta {
		grid-column: 8 / span 3;
	}
}

.tickets-list__item__cta-text {
	cursor: not-allowed;
}
