:root {
	/* #region Color Atoms */
	--color-black: rgb(0 0 0);
	--color-black-80: rgb(0 0 0 / 0.8);
	--color-black-50: rgb(0 0 0 / 0.5);
	--color-white: rgb(255 255 255);
	--color-white-85: rgb(255 255 255 / 0.85);
	--color-white-65: rgb(255 255 255 / 0.65);
	--color-grey: rgb(189 201 204);
	--color-grey-50: rgb(189 201 204 / 0.5);
	--color-green: rgb(92 201 61);
	--color-green-50: rgb(92 201 61 / 0.5);
	--color-green-dark: rgb(23 51 15);
	--color-red: rgb(255 0 0);
	--color-red-50: rgb(255 0 0 / 0.5);
	--color-red-dark: rgb(99 0 0);
	--color-blue: rgb(191 209 250);
	--color-blue-50: rgb(191 209 250 / 0.5);
	--color-blue-dark: rgb(0 61 148);
	--color-yellow: rgb(250 232 79);
	--color-yellow-50: rgb(250 232 79 / 0.5);
	--color-yellow-dark: rgb(110 59 18);
	--color-purple: rgb(128 92 235);
	--color-purple-50: rgb(128 92 235 / 0.5);
	--color-purple-dark: rgb(13 31 66);
	/* #endregion Color Atoms */

	/* #region Typography */
	--font-sans: "Baikal", "Baikal Fallback", "Arial Bold", sans-serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-5--px: 5px;
	--space-5--rem: 0.3125rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-30--px: 30px;
	--space-30--rem: 1.875rem;
	--space-50--px: 50px;
	--space-50--rem: 3.125rem;
	--space-100--px: 100px;
	--space-100--rem: 6.25rem;
	/* #endregion Spacers */

	/* #region Border Radius */
	--border-radius-9999: 9999px;
	/* #endregion Border Radius */
}

[data-theme="light"],
[data-theme="monochrome"] {
	color-scheme: light dark;
}

[data-theme="dark"],
[data-theme="monochrome-inverted"] {
	color-scheme: dark;
}

/*
	The comments before each variable are the names as placed in Figma.
	These were often non-ideal and didn't follow our naming conventions.
	They are preserved here so that we can lookup values in Figma.

	If a value does not exist in Figma it should be annotated with "missing"
*/

[data-theme="light"] {
	/* foreground-color */
	--color-foreground: var(--color-black);
	/* background-color */
	--color-background: var(--color-grey);
	/* highlight-color */
	--color-highlight: var(--color-black);
	/* accent-color */
	--color-accent: var(--color-white);
	/* button-background-color */
	--color-button-background: var(--color-white-65);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-white-85);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-white);
	/* button-foreground */
	--color-button-foreground: var(--color-black-50);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-black);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-black);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-white-85);
	/* missing */
	--color-sub-navigation-foreground: var(--color-black-50);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-black);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-black);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-white-65);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-white);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-white);
	/* accordion */
	--color-accordion: var(--color-black);
	/* accordion-hover */
	--color-accordion-hover: var(--color-white);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-black-50);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-white-65);
	/* inline-link */
	--color-inline-link: var(--color-black);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-white);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-white);
	/* artist-info-background */
	--color-artist-info-background: var(--color-black);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-grey);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-white);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-white-65);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-white-85);
	/* input-foreground */
	--color-input-foreground: var(--color-white);
	/* list-item-border */
	--color-list-item-border: var(--color-white);

	/* input-radio-background */
	--color-input-radio: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/></svg>');
	/* input-radio-background, input-radio-foreground */
	--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/><circle cx="12" cy="12" r="8" fill="black"/></svg>');

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-grey);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-white);
	/* missing */
	--color-sponsor-background: transparent;
	/* missing */
	--color-sponsor-background-hover: var(--color-white-65);
}

@media (prefers-color-scheme: dark) {
	[data-theme="light"] {
		/* foreground-color */
		--color-foreground: var(--color-grey);
		/* background-color */
		--color-background: var(--color-black);
		/* highlight-color */
		--color-highlight: var(--color-black);
		/* accent-color */
		--color-accent: var(--color-white);
		/* button-background-color */
		--color-button-background: var(--color-white-65);
		/* button-background-color-hover */
		--color-button-background-hover: var(--color-white-85);
		/* button-background-color-selected */
		--color-button-background-active: var(--color-white);
		/* button-foreground */
		--color-button-foreground: var(--color-black-50);
		/* button-foreground-hover */
		--color-button-foreground-hover: var(--color-black);
		/* button-foreground-hover (duplicated) */
		--color-button-foreground-active: var(--color-black);
		/* navigation-background */
		--color-sub-navigation-background: var(--color-white-85);
		/* missing */
		--color-sub-navigation-foreground: var(--color-black-50);
		/* missing */
		--color-sub-navigation-foreground-hover: var(--color-black);
		/* missing */
		--color-sub-navigation-foreground-active: var(--color-black);
		/* subnav-item-color */
		--color-anchor-navigation-item-foreground: var(--color-white-65);
		/* subnav-item-color-hover */
		--color-anchor-navigation-item-foreground-hover: var(--color-white);
		/* subnav-item-color-selected */
		--color-anchor-navigation-item-foreground-active: var(--color-white);
		/* accordion */
		--color-accordion: var(--color-grey);
		/* accordion-hover */
		--color-accordion-hover: var(--color-white);
		/* accordion-notforsale */
		--color-accordion-not-for-sale: var(--color-grey-50);
		/* accordion-notforsale-hover */
		--color-accordion-not-for-sale-hover: var(--color-white-65);
		/* inline-link */
		--color-inline-link: var(--color-grey);
		/* inline-link-hover */
		--color-inline-link-hover: var(--color-white);
		/* inline-link-hover (duplicated) */
		--color-inline-link-current: var(--color-white);
		/* artist-info-background */
		--color-artist-info-background: var(--color-grey);
		/* artist-info-foreground */
		--color-artist-info-foreground: var(--color-black);
		/* artist-info-link-hover */
		--color-artist-info-link-hover: var(--color-white);
		/* missing */
		--color-teaser-a-placeholder-foreground: var(--color-background);
		/* input-hint */
		--color-input-hint: var(--color-white-65);
		/* input-hint-hover */
		--color-input-hint-hover: var(--color-white-85);
		/* input-foreground */
		--color-input-foreground: var(--color-white);
		/* list-item-border */
		--color-list-item-border: var(--color-white);

		/* missing */
		--color-sponsor-placeholder-foreground: var(--color-black);
		/* missing */
		--color-sponsor-placeholder-background: var(--color-white);
		/* missing */
		--color-sponsor-background: var(--color-white-65);
		/* missing */
		--color-sponsor-background-hover: var(--color-white-85);
	}
}

[data-theme="dark"] {
	/* foreground-color */
	--color-foreground: var(--color-grey);
	/* background-color */
	--color-background: var(--color-black);
	/* highlight-color */
	--color-highlight: var(--color-black);
	/* accent-color */
	--color-accent: var(--color-white);
	/* button-background-color */
	--color-button-background: var(--color-white-65);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-white-85);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-white);
	/* button-foreground */
	--color-button-foreground: var(--color-black-50);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-black);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-black);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-white-85);
	/* missing */
	--color-sub-navigation-foreground: var(--color-black-50);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-black);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-black);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-white-65);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-white);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-white);
	/* accordion */
	--color-accordion: var(--color-grey);
	/* accordion-hover */
	--color-accordion-hover: var(--color-white);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-grey-50);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-white-65);
	/* inline-link */
	--color-inline-link: var(--color-grey);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-white);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-white);
	/* artist-info-background */
	--color-artist-info-background: var(--color-grey);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-black);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-white);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-white-65);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-white-85);
	/* input-foreground */
	--color-input-foreground: var(--color-white);
	/* list-item-border */
	--color-list-item-border: var(--color-white);

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-black);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-white);
	/* missing */
	--color-sponsor-background: var(--color-white-65);
	/* missing */
	--color-sponsor-background-hover: var(--color-white-85);
}

[data-theme="red"] {
	/* foreground-color */
	--color-foreground: var(--color-red);
	/* background-color */
	--color-background: var(--color-red-dark);
	/* highlight-color */
	--color-highlight: var(--color-red);
	/* accent-color */
	--color-accent: var(--color-white);
	/* button-background-color */
	--color-button-background: var(--color-white-65);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-white-85);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-white);
	/* button-foreground */
	--color-button-foreground: var(--color-red-dark);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-red);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-red);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-white-85);
	/* missing */
	--color-sub-navigation-foreground: var(--color-red-dark);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-red);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-red);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-white-65);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-white);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-white);
	/* accordion */
	--color-accordion: var(--color-red);
	/* accordion-hover */
	--color-accordion-hover: var(--color-white);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-red-50);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-white-65);
	/* inline-link */
	--color-inline-link: var(--color-red);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-white);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-white);
	/* artist-info-background */
	--color-artist-info-background: var(--color-red);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-red-dark);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-white);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-white-65);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-white-85);
	/* input-foreground */
	--color-input-foreground: var(--color-white);
	/* list-item-border */
	--color-list-item-border: var(--color-white);

	/* input-radio-background, input-radio-foreground */
	--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/><circle cx="12" cy="12" r="8" fill="%23ff0000"/></svg>');

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-red-dark);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-white);
	/* missing */
	--color-sponsor-background: var(--color-white-65);
	/* missing */
	--color-sponsor-background-hover: var(--color-white-85);
}

[data-theme="green"] {
	/* foreground-color */
	--color-foreground: var(--color-green);
	/* background-color */
	--color-background: var(--color-green-dark);
	/* highlight-color */
	--color-highlight: var(--color-green);
	/* accent-color */
	--color-accent: var(--color-white);
	/* button-background-color */
	--color-button-background: var(--color-white-65);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-white-85);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-white);
	/* button-foreground */
	--color-button-foreground: var(--color-green-dark);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-green);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-green);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-white-85);
	/* missing */
	--color-sub-navigation-foreground: var(--color-green-dark);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-green);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-green);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-white-65);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-white);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-white);
	/* accordion */
	--color-accordion: var(--color-green);
	/* accordion-hover */
	--color-accordion-hover: var(--color-white);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-green-50);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-white-65);
	/* inline-link */
	--color-inline-link: var(--color-green);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-white);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-white);
	/* artist-info-background */
	--color-artist-info-background: var(--color-green);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-green-dark);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-white);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-white-65);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-white-85);
	/* input-foreground */
	--color-input-foreground: var(--color-white);
	/* list-item-border */
	--color-list-item-border: var(--color-white);

	/* input-radio-background, input-radio-foreground */
	--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/><circle cx="12" cy="12" r="8" fill="%235cc93d"/></svg>');

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-green-dark);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-white);
	/* missing */
	--color-sponsor-background: var(--color-white-65);
	/* missing */
	--color-sponsor-background-hover: var(--color-white-85);
}

[data-theme="purple"] {
	/* foreground-color */
	--color-foreground: var(--color-purple);
	/* background-color */
	--color-background: var(--color-purple-dark);
	/* highlight-color */
	--color-highlight: var(--color-purple);
	/* accent-color */
	--color-accent: var(--color-white);
	/* button-background-color */
	--color-button-background: var(--color-white-65);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-white-85);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-white);
	/* button-foreground */
	--color-button-foreground: var(--color-purple-dark);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-purple);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-purple);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-white-85);
	/* missing */
	--color-sub-navigation-foreground: var(--color-purple-dark);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-purple);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-purple);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-white-65);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-white);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-white);
	/* accordion */
	--color-accordion: var(--color-purple);
	/* accordion-hover */
	--color-accordion-hover: var(--color-white);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-purple-50);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-white-65);
	/* inline-link */
	--color-inline-link: var(--color-purple);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-white);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-white);
	/* artist-info-background */
	--color-artist-info-background: var(--color-purple);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-purple-dark);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-white);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-white-65);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-white-85);
	/* input-foreground */
	--color-input-foreground: var(--color-white);
	/* list-item-border */
	--color-list-item-border: var(--color-white);

	/* input-radio-background, input-radio-foreground */
	--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/><circle cx="12" cy="12" r="8" fill="%23805ceb"/></svg>');

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-purple-dark);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-white);
	/* missing */
	--color-sponsor-background: var(--color-white-65);
	/* missing */
	--color-sponsor-background-hover: var(--color-white-85);
}

[data-theme="blue"] {
	/* foreground-color */
	--color-foreground: var(--color-blue);
	/* background-color */
	--color-background: var(--color-blue-dark);
	/* highlight-color */
	--color-highlight: var(--color-blue);
	/* accent-color */
	--color-accent: var(--color-white);
	/* button-background-color */
	--color-button-background: var(--color-black-50);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-black-80);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-black);
	/* button-foreground */
	--color-button-foreground: var(--color-blue-dark);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-blue);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-blue);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-black-80);
	/* missing */
	--color-sub-navigation-foreground: var(--color-blue-dark);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-blue);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-blue);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-white-65);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-white);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-white);
	/* accordion */
	--color-accordion: var(--color-blue);
	/* accordion-hover */
	--color-accordion-hover: var(--color-white);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-blue-50);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-white-65);
	/* inline-link */
	--color-inline-link: var(--color-blue);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-white);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-white);
	/* artist-info-background */
	--color-artist-info-background: var(--color-blue);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-blue-dark);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-white);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-white-65);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-white-85);
	/* input-foreground */
	--color-input-foreground: var(--color-white);
	/* list-item-border */
	--color-list-item-border: var(--color-white);

	/* input-radio-background, input-radio-foreground */
	--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/><circle cx="12" cy="12" r="8" fill="%23003d94"/></svg>');

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-blue-dark);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-white);
	/* missing */
	--color-sponsor-background: var(--color-white-65);
	/* missing */
	--color-sponsor-background-hover: var(--color-white-85);
}

[data-theme="yellow"] {
	/* foreground-color */
	--color-foreground: var(--color-yellow);
	/* background-color */
	--color-background: var(--color-yellow-dark);
	/* highlight-color */
	--color-highlight: var(--color-yellow);
	/* accent-color */
	--color-accent: var(--color-white);
	/* button-background-color */
	--color-button-background: var(--color-black-50);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-black-80);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-black);
	/* button-foreground */
	--color-button-foreground: var(--color-yellow-dark);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-yellow);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-yellow);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-black-80);
	/* missing */
	--color-sub-navigation-foreground: var(--color-yellow-dark);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-yellow);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-yellow);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-white-65);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-white);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-white);
	/* accordion */
	--color-accordion: var(--color-yellow);
	/* accordion-hover */
	--color-accordion-hover: var(--color-white);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-yellow-50);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-white-65);
	/* inline-link */
	--color-inline-link: var(--color-yellow);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-white);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-white);
	/* artist-info-background */
	--color-artist-info-background: var(--color-yellow);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-yellow-dark);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-white);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-white-65);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-white-85);
	/* input-foreground */
	--color-input-foreground: var(--color-white);
	/* list-item-border */
	--color-list-item-border: var(--color-white);

	/* input-radio-background, input-radio-foreground */
	--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/><circle cx="12" cy="12" r="8" fill="%236e3b12"/></svg>');

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-yellow-dark);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-white);
	/* missing */
	--color-sponsor-background: var(--color-white-65);
	/* missing */
	--color-sponsor-background-hover: var(--color-white-85);
}

:root,
[data-theme="monochrome"] {
	/* foreground-color */
	--color-foreground: var(--color-black);
	/* background-color */
	--color-background: var(--color-white);
	/* highlight-color */
	--color-highlight: var(--color-black);
	/* accent-color */
	--color-accent: var(--color-white);
	/* button-background-color */
	--color-button-background: var(--color-black);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-white);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-black);
	/* button-foreground */
	--color-button-foreground: var(--color-white);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-black);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-white);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-black);
	/* missing */
	--color-sub-navigation-foreground: var(--color-white);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-white-65);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-white);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-black);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-black-50);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-black);
	/* accordion */
	--color-accordion: var(--color-black);
	/* accordion-hover */
	--color-accordion-hover: var(--color-black);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-black-50);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-black-50);
	/* inline-link */
	--color-inline-link: var(--color-black);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-black-80);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-black);
	/* artist-info-background */
	--color-artist-info-background: var(--color-black);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-white);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-white);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-black-50);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-black-80);
	/* input-foreground */
	--color-input-foreground: var(--color-black);
	/* list-item-border */
	--color-list-item-border: var(--color-black);

	/* input-radio-background */
	--color-input-radio: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="black"/><circle cx="12" cy="12" r="8" fill="white"/></svg>');
	/* input-radio-background, input-radio-foreground */
	--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="black"/></svg>');

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-white);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-black);
	/* missing */
	--color-sponsor-background: var(--color-white);
	/* missing */
	--color-sponsor-background-hover: var(--color-black-50);
}

@media (prefers-color-scheme: dark) {
	:root,
	[data-theme="monochrome"] {
		/* foreground-color */
		--color-foreground: var(--color-white);
		/* background-color */
		--color-background: var(--color-black);
		/* highlight-color */
		--color-highlight: var(--color-white);
		/* accent-color */
		--color-accent: var(--color-black);
		/* button-background-color */
		--color-button-background: var(--color-white);
		/* button-background-color-hover */
		--color-button-background-hover: var(--color-black);
		/* button-background-color-selected */
		--color-button-background-active: var(--color-white);
		/* button-foreground */
		--color-button-foreground: var(--color-black);
		/* button-foreground-hover */
		--color-button-foreground-hover: var(--color-white);
		/* button-foreground-hover (duplicated) */
		--color-button-foreground-active: var(--color-black);
		/* navigation-background */
		--color-sub-navigation-background: var(--color-white);
		/* missing */
		--color-sub-navigation-foreground: var(--color-black);
		/* missing */
		--color-sub-navigation-foreground-hover: var(--color-black-50);
		/* missing */
		--color-sub-navigation-foreground-active: var(--color-black);
		/* subnav-item-color */
		--color-anchor-navigation-item-foreground: var(--color-white);
		/* subnav-item-color-hover */
		--color-anchor-navigation-item-foreground-hover: var(--color-white-65);
		/* subnav-item-color-selected */
		--color-anchor-navigation-item-foreground-active: var(--color-white-65);
		/* accordion */
		--color-accordion: var(--color-white);
		/* accordion-hover */
		--color-accordion-hover: var(--color-white-85);
		/* accordion-notforsale */
		--color-accordion-not-for-sale: var(--color-white-65);
		/* accordion-notforsale-hover */
		--color-accordion-not-for-sale-hover: var(--color-white-65);
		/* inline-link */
		--color-inline-link: var(--color-white);
		/* inline-link-hover */
		--color-inline-link-hover: var(--color-white-85);
		/* inline-link-hover (duplicated) */
		--color-inline-link-current: var(--color-white);
		/* artist-info-background */
		--color-artist-info-background: var(--color-white);
		/* artist-info-foreground */
		--color-artist-info-foreground: var(--color-black);
		/* artist-info-link-hover */
		--color-artist-info-link-hover: var(--color-black);
		/* missing */
		--color-teaser-a-placeholder-foreground: var(--color-background);
		/* input-hint */
		--color-input-hint: var(--color-white-65);
		/* input-hint-hover */
		--color-input-hint-hover: var(--color-white-85);
		/* input-foreground */
		--color-input-foreground: var(--color-white);
		/* list-item-border */
		--color-list-item-border: var(--color-white);

		/* input-radio-background */
		--color-input-radio: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/><circle cx="12" cy="12" r="8" fill="black"/></svg>');
		/* input-radio-background, input-radio-foreground */
		--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/></svg>');

		/* missing */
		--color-sponsor-placeholder-foreground: var(--color-black);
		/* missing */
		--color-sponsor-placeholder-background: var(--color-white);
		/* missing */
		--color-sponsor-background: var(--color-white);
		/* missing */
		--color-sponsor-background-hover: var(--color-white);
	}
}

[data-theme="monochrome-inverted"] {
	/* foreground-color */
	--color-foreground: var(--color-white);
	/* background-color */
	--color-background: var(--color-black);
	/* highlight-color */
	--color-highlight: var(--color-white);
	/* accent-color */
	--color-accent: var(--color-black);
	/* button-background-color */
	--color-button-background: var(--color-white);
	/* button-background-color-hover */
	--color-button-background-hover: var(--color-black);
	/* button-background-color-selected */
	--color-button-background-active: var(--color-white);
	/* button-foreground */
	--color-button-foreground: var(--color-black);
	/* button-foreground-hover */
	--color-button-foreground-hover: var(--color-white);
	/* button-foreground-hover (duplicated) */
	--color-button-foreground-active: var(--color-black);
	/* navigation-background */
	--color-sub-navigation-background: var(--color-white);
	/* missing */
	--color-sub-navigation-foreground: var(--color-black);
	/* missing */
	--color-sub-navigation-foreground-hover: var(--color-black-50);
	/* missing */
	--color-sub-navigation-foreground-active: var(--color-black);
	/* subnav-item-color */
	--color-anchor-navigation-item-foreground: var(--color-white);
	/* subnav-item-color-hover */
	--color-anchor-navigation-item-foreground-hover: var(--color-white-65);
	/* subnav-item-color-selected */
	--color-anchor-navigation-item-foreground-active: var(--color-white-65);
	/* accordion */
	--color-accordion: var(--color-white);
	/* accordion-hover */
	--color-accordion-hover: var(--color-white-85);
	/* accordion-notforsale */
	--color-accordion-not-for-sale: var(--color-white-65);
	/* accordion-notforsale-hover */
	--color-accordion-not-for-sale-hover: var(--color-white-65);
	/* inline-link */
	--color-inline-link: var(--color-white);
	/* inline-link-hover */
	--color-inline-link-hover: var(--color-white-85);
	/* inline-link-hover (duplicated) */
	--color-inline-link-current: var(--color-white);
	/* artist-info-background */
	--color-artist-info-background: var(--color-white);
	/* artist-info-foreground */
	--color-artist-info-foreground: var(--color-black);
	/* artist-info-link-hover */
	--color-artist-info-link-hover: var(--color-black);
	/* missing */
	--color-teaser-a-placeholder-foreground: var(--color-background);
	/* input-hint */
	--color-input-hint: var(--color-white-65);
	/* input-hint-hover */
	--color-input-hint-hover: var(--color-white-85);
	/* input-foreground */
	--color-input-foreground: var(--color-white);
	/* list-item-border */
	--color-list-item-border: var(--color-white);

	/* input-radio-background */
	--color-input-radio: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/><circle cx="12" cy="12" r="8" fill="black"/></svg>');
	/* input-radio-background, input-radio-foreground */
	--color-input-radio-checked: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" fill="white"/></svg>');

	/* missing */
	--color-sponsor-placeholder-foreground: var(--color-black);
	/* missing */
	--color-sponsor-placeholder-background: var(--color-white);
	/* missing */
	--color-sponsor-background: var(--color-white);
	/* missing */
	--color-sponsor-background-hover: var(--color-white);
}
