:root {
	text-underline-position: from-font;
	-webkit-text-size-adjust: 100%;
}

body {
	box-sizing: border-box;
	margin: 0;
	position: relative;
	min-height: 100vh;
}

template {
	display: none;
}

textarea {
	resize: none;
}

button,
input,
optgroup,
select,
textarea {
	font: inherit;
	margin: 0;
}

button,
input {
	overflow: visible;
}

hr {
	background-color: currentColor;
	border: none;
	box-sizing: content-box;
	overflow: visible;
	height: 0.0625rem;
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

.wp-block-table {
	margin: 0; /* Remove margin from Figure tag set by Gutenberg */
}

pre {
	white-space: pre-wrap;
}

/*
 * Give "display: none;" for elements with a "hidden" attribute specificity [0, 2, 0].
 * This will generally be higher specificity than layout directives : "display: flex".
 * Because this is declared very early you can easily override it with ".foo[hidden]".
 */
[hidden][hidden] {
	display: none;
}

@supports (content-visibility: hidden) and (display: revert) {
	[hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}
