.intro-animation {
	background-color: var(--color-background);
	display: none;
	z-index: 999999;
}

[data-show-intro-animation] .intro-animation {
	display: block;
	transition: height 768ms ease-in;
	width: 100%;
	height: 100vh;
	height: 100svh;
}

[data-shrink-intro-animation] .intro-animation {
	height: 0px;
}

.intro-animation svg {
	box-sizing: border-box;
	padding-inline: var(--space-10--px);
	padding-block: var(--space-10--rem);
	width: 100%;
	height: 100%;
}
