.teaser-list-a {
}

.teaser-list-a__title {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(2, minmax(0, 1fr));
	margin-block-end: var(--space-10--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-list-a__title {
		column-gap: var(--space-10--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.teaser-list-a__title {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.teaser-list-a__title__part {
	min-width: 0;
}

.teaser-list-a__list {
	display: grid;
	row-gap: var(--space-20--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(1, minmax(0, 1fr));
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.teaser-list-a__list {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-list-a__list {
		column-gap: var(--space-10--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.teaser-list-a__list {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.teaser-list-a__list-item {
	min-width: 0;
}
