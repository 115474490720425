.asset-grid {
	display: grid;
	row-gap: var(--space-10--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: minmax(0, 1fr);
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.asset-grid {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.asset-grid__item {
	display: block;
	line-height: 0;
	margin: 0;
	padding: 0;
	min-width: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.asset-grid__item[data-size="100"] {
		grid-column: 1 / span 3;
	}

	.asset-grid__item[data-size="33"] {
		grid-column: 1 / span 1;
	}

	.asset-grid__item[data-size="33"][data-is-second-item] {
		grid-column: 3 / span 1;
	}

	.asset-grid__item[data-size="66"] {
		grid-column: 1 / span 2;
	}

	.asset-grid__item[data-size="66"][data-is-second-item] {
		grid-column: 2 / span 2;
	}
}
