.video {
	border-radius: 15px;
	width: 100%;
	height: auto;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.video {
		border-radius: 20px;
	}
}
