@font-face {
	font-family: "Baikal Fallback";
	font-style: normal;
	font-weight: 650;
	src: local(Arial Bold);
	ascent-override: 98.96%;
	descent-override: 27.87%;
	line-gap-override: 0%;
	size-adjust: 99.17%;
}

@font-face {
	font-family: "Baikal";
	src: url("../../fonts/common/baikal.woff2") format("woff2");
	font-style: normal;
	font-weight: 650;
	font-display: swap;
	unicode-range: U+0000-0080, U+0098-0099, U+00A0, U+00A9, U+00AD, U+00B0, U+00C2, U+00C5, U+00D7, U+00E0-00FC, U+0101, U+0106, U+010D, U+0110, U+0113, U+0117-0119, U+012B, U+0161-0162, U+017D-017E, U+01A1, U+01B0, U+0301, U+0327, U+0422, U+1ED3, U+200B, U+2010, U+2013-2014, U+2018-2019, U+201C-201D, U+2022, U+2026, U+203A, U+20AC, U+2191-2193, U+21A9, U+21D2, U+2264, U+23E4, U+25A0, U+25AA, U+25E6, U+26A1, U+30FB, U+FE0E-FE0F;
}

@font-face {
	font-family: "Baikal";
	src: url("../../fonts/extended/baikal.woff2") format("woff2");
	font-style: normal;
	font-weight: 650;
	font-display: swap;
	unicode-range: U+0081-0097, U+009A-009F, U+00A1-00A8, U+00AA-00AC, U+00AE-00AF, U+00B1-00C1, U+00C3-00C4, U+00C6-00D6, U+00D8-00DF, U+00FD-0100, U+0102-0105, U+0107-010C, U+010E-010F, U+0111-0112, U+0114-0116, U+011A-012A, U+012C-0160, U+0163-017C, U+017F-01A0, U+01A2-01AF, U+01B1-0300, U+0302-0326, U+0328-0421, U+0423-1ED2, U+1ED4-200A, U+200C-200F, U+2011-2012, U+2015-2017, U+201A-201B, U+201E-2021, U+2023-2025, U+2027-2039, U+203B-20AB, U+20AD-2190, U+2194-21A8, U+21AA-21D1, U+21D3-2263, U+2265-23E3, U+23E5-259F, U+25A1-25A9, U+25AB-25E5, U+25E7-26A0, U+26A2-30FA, U+30FC-FE0D, U+FE10-FFFFF;
}
