.hotspots-list {
	counter-reset: hotspots;
	min-height: 200px;
}

.hotspots-list__filter {
	border: none;
	display: grid;
	column-gap: var(--space-5--px);
	margin: 0;
	margin-block-end: var(--space-100--rem);
	outline: none;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hotspots-list__filter {
		column-gap: var(--space-10--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hotspots-list__filter {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

[data-has-small-row-gaps] .hotspots-list__filter {
	margin-block-end: var(--space-30--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	[data-has-small-row-gaps] .hotspots-list__filter {
		margin-block-end: var(--space-50--rem);
	}
}

.hotspots-list__filter__radio-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	row-gap: var(--space-10--px);
	column-gap: var(--space-20--px);
	min-width: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hotspots-list__filter__radio-group {
		grid-column: 2 / span 2;
	}
}

.hotspots-list__filter__radio-with-label {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 0;
}

.hotspots-list__filter__radio-with-label input[type="radio"] {
	appearance: none;
	background: transparent;
	background-image: var(--color-input-radio);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	width: 1rem;
	min-width: 0;
	height: 1rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hotspots-list__filter__radio-with-label input[type="radio"] {
		width: 1.25rem;
		height: 1.25rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.hotspots-list__filter__radio-with-label input[type="radio"] {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.hotspots-list__filter__radio-with-label input[type="radio"]:checked {
	background-image: var(--color-input-radio-checked);
}

.hotspots-list__filter__radio-with-label label {
	padding-inline-start: var(--space-10--px);
	min-width: 0;
}

.hotspots-list__list {
	display: grid;
	row-gap: var(--space-5--rem);
	list-style-type: none;
	margin: 0;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hotspots-list__list {
		row-gap: var(--space-10--rem);
	}
}

.hotspots-list__list-item {
	animation: linear both;
	animation-timeline: view(block 120px 50px);
	border-block-start: 0.0625rem solid var(--color-list-item-border);
	display: grid;
	row-gap: var(--space-5--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(6, minmax(0, 1fr));
	line-height: 0;
	padding-block-start: var(--space-5--rem);
	min-width: 0;
}

@media (prefers-reduced-motion: no-preference) {
	.hotspots-list__list-item {
		animation-name: HOTSPOTS_SCROLL_ANIMATION;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hotspots-list__list-item {
		animation-timeline: view(block 130px 50px);
		grid-template-columns: repeat(18, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hotspots-list__list-item {
		animation-timeline: view(block 160px 50px);
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(6, minmax(0, 1fr));
		padding-block-start: var(--space-10--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hotspots-list__list-item {
		animation-timeline: view(block 120px 50px);
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
}

.hotspots-list__list-item[hidden] {
	display: none;
}

.hotspots-list__list-item__title {
	grid-column: 1 / span 2;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hotspots-list__list-item__title {
		grid-column: 1 / span 9;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hotspots-list__list-item__title {
		grid-column: 1 / span 3;
	}
}

/* from-medium,tablet-l,min-width-1024 */
@media (width >= 64rem) {
	.hotspots-list__list-item__title {
		grid-column: 1 / span 2;
	}
}

.hotspots-list__list-item__type {
	grid-column: 4 / span 3;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hotspots-list__list-item__type {
		grid-column: 10 / span 9;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hotspots-list__list-item__type {
		grid-column: 4 / span 3;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hotspots-list__list-item__type {
		grid-column: 3 / span 1;
	}
}

.hotspots-list__list-item__text {
	grid-column: 1 / span 6;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hotspots-list__list-item__text {
		grid-column: 1 / span 18;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hotspots-list__list-item__text {
		grid-column: 1 / span 6;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hotspots-list__list-item__text {
		grid-column: 4 / span 3;
	}
}

.hotspots-list__list-item__links {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	grid-column: 1 / span 6;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hotspots-list__list-item__links {
		grid-column: 1 / span 18;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hotspots-list__list-item__links {
		grid-column: 1 / span 6;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.hotspots-list__list-item__links {
		grid-column: 7 / span 3;
		justify-content: end;
	}
}

.hotspots-list__list-item__links > a {
	min-width: 0;
}

.hotspots-list__list-item__links > a:not(:first-child) {
	margin-inline-start: var(--space-10--px);
}

@keyframes HOTSPOTS_SCROLL_ANIMATION {
	entry 0% {
		opacity: 0;
		transform: translateY(100%);
	}

	entry 100% {
		opacity: 1;
		transform: translateY(0);
	}

	exit 0% {
		opacity: 1;
		transform: translateY(0);
	}

	exit 100% {
		opacity: 0;
		transform: translateY(-150%);
	}
}
