.text-33-33-33 {
	display: grid;
	row-gap: var(--space-10--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(1, minmax(0, 1fr));
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-33-33-33 {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.text-33-33-33 {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.text-33-33-33__title {
	grid-column: 1 / span 1;
	min-width: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-33-33-33__title {
		grid-column: 1 / span 2;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.text-33-33-33__title {
		grid-column: 1 / span 3;
	}
}

.text-33-33-33__column-a {
	grid-column: 1 / span 1;
	min-width: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-33-33-33__column-a {
		grid-column: 1 / span 1;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.text-33-33-33__column-a {
		grid-column: 1 / span 1;
	}
}

.text-33-33-33__column-b {
	grid-column: 1 / span 1;
	min-width: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-33-33-33__column-b {
		grid-column: 2 / span 1;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.text-33-33-33__column-b {
		grid-column: 2 / span 1;
	}
}

.text-33-33-33__column-c {
	grid-column: 1 / span 1;
	min-width: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-33-33-33__column-c {
		grid-column: 1 / span 2;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.text-33-33-33__column-c {
		grid-column: 3 / span 1;
	}
}

:is(.text-33-33-33__column-a, .text-33-33-33__column-b, .text-33-33-33__column-c) > :first-child {
	margin-block-start: 0;
}

:is(.text-33-33-33__column-a, .text-33-33-33__column-b, .text-33-33-33__column-c) > :last-child {
	margin-block-end: 0;
}
