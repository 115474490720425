.form {
	box-sizing: border-box;
	display: grid;
	font-size: 16px;
	font-size: max(16px, 1rem);
	row-gap: var(--space-10--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: minmax(0, 1fr);
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.form {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.form__heading {
	min-width: 0;
}

.form__rows {
	min-width: 0;
}

.form__row {
	margin-block: var(--space-20--rem);
}

.form__row:first-child {
	margin-block-start: 0;
}

.form__row:last-child {
	margin-block-end: 0;
}

.form__text-input-label {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
}

.form__text-input-label[data-is-required]::after {
	content: "*" / "";
}

.form__text-input {
	background-color: transparent;
	border: 0;
	color: var(--color-input-foreground);
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
}

.form__text-input:focus {
	outline: none;
}

.form__text-input::placeholder {
	color: var(--color-input-hint);
	transition: color 96ms linear;
}

.form__text-input:hover::placeholder,
.form__text-input:focus::placeholder {
	color: var(--color-input-hint-hover);
}

.form__submit {
	min-width: 0;
}
