.artist-information-mobile {
	display: grid;
	row-gap: var(--space-10--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.artist-information-mobile {
		column-gap: var(--space-10--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.artist-information-mobile {
		display: none;
	}
}

:is(.artist-information-mobile__title, .artist-information-mobile__city, .artist-information-mobile__job-title, .artist-information-mobile__extra-label, .artist-information-mobile__biography) > :first-child {
	margin-block-start: 0;
}

:is(.artist-information-mobile__title, .artist-information-mobile__city, .artist-information-mobile__job-title, .artist-information-mobile__extra-label, .artist-information-mobile__biography) > :last-child {
	margin-block-end: 0;
}

.artist-information-mobile__title {
	grid-column: 1 / span 1;
	min-width: 0;
}

.artist-information-mobile__city {
	grid-column: 2 / span 1;
	min-width: 0;
}

.artist-information-mobile__job-title {
	grid-column: 1 / span 1;
	min-width: 0;
}

.artist-information-mobile__extra-label {
	grid-column: 2 / span 1;
	min-width: 0;
}

.artist-information-mobile__biography {
	grid-column: 1 / span 2;
	min-width: 0;
}

.artist-information-mobile__links {
	grid-column: 1 / span 2;
	min-width: 0;
}

.artist-information-mobile__portrait {
	grid-column: 1 / span 2;
	min-width: 0;
}

.artist-information-mobile__portrait__image {
	border-radius: 10px;
	width: 100px;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.artist-information-mobile__portrait__image {
		width: 125px;
	}
}
