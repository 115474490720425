.teaser-a {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	text-decoration: none;
	height: 100%;
}

.teaser-a:hover {
	color: currentColor;
}

.teaser-a__header {
	display: flex;
	flex-wrap: wrap;
	margin-block-end: var(--space-5--rem);
	overflow-wrap: anywhere;
	min-width: 0;
}

.teaser-a__title {
	margin-inline-end: var(--space-10--px);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-a__title {
		margin-inline-end: var(--space-20--px);
	}
}

.teaser-a__title,
.teaser-a__city {
	min-width: 0;
}

.teaser-a__content {
	display: grid;
	min-width: 0;
}

.teaser-a__meta-container {
	aspect-ratio: 3 / 2;
	display: flex;
	grid-row: 1;
	grid-column: 1;
	width: 100%;
	min-width: 0;
	height: 100%;
}

.teaser-a__meta {
	opacity: 0;
	transition: opacity 96ms linear;
	width: 100%;
	min-width: 0;
	height: 100%;
}

@media (hover: hover) {
	.teaser-a[data-has-content-meta]:hover .teaser-a__meta {
		opacity: 1;
	}
}

.teaser-a__job-title {
	margin-block-end: var(--space-5--rem);
}

.teaser-a__media-container {
	aspect-ratio: 3 / 2;
	grid-row: 1;
	grid-column: 1;
	transition: opacity 96ms linear;
	width: 100%;
	min-width: 0;
	height: 100%;
}

@media (hover: hover) {
	.teaser-a[data-has-content-meta]:hover .teaser-a__media-container {
		opacity: 0;
	}
}

.teaser-a__media-container mr-video {
	display: inline;
}

.teaser-a__image,
.teaser-a__video {
	border-radius: 10px;
	object-fit: cover;
	transition: background-color 96ms linear;
	width: 100%;
	height: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-a__image,
	.teaser-a__video {
		border-radius: 20px;
	}
}

.teaser-a[data-is-placeholder] .teaser-a__image {
	background-color: currentColor;
	box-sizing: border-box;
	display: grid;
}

.teaser-a[data-is-placeholder] .teaser-a__placeholder {
	padding-inline: 5px;
	padding-block: 0.3125rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-a[data-is-placeholder] .teaser-a__placeholder {
		padding-inline: 10px;
		padding-block: 0.625rem;
	}
}

.teaser-a[data-is-placeholder] .teaser-a__placeholder,
.teaser-a[data-is-placeholder] .teaser-a__placeholder a {
	color: var(--color-teaser-a-placeholder-foreground);
}
