.text-33-66 {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-areas:
		"title title"
		". column-a"
		"column-b column-b";
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-33-66 {
		column-gap: var(--space-10--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.text-33-66 {
		display: grid;
		grid-template-areas:
			"title title title"
			"column-a column-b column-b";
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.text-33-66__title {
	grid-area: title;
	min-width: 0;
}

.text-33-66__column-a {
	grid-area: column-a;
	min-width: 0;
}

.text-33-66__column-b {
	grid-area: column-b;
	min-width: 0;
}

:is(.text-33-66__column-a, .text-33-66__column-b) > :first-child {
	margin-block-start: 0;
}

/* up-to-medium,max-width-1023px */
@media (width < 64rem) {
	.text-33-66__column-b > :first-child {
		margin-block-start: var(--space-10--rem);
	}
}

:is(.text-33-66__column-a, .text-33-66__column-b) > :last-child {
	margin-block-end: 0;
}
