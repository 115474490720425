.site-header-container {
	--header-logo-height: calc((((100vw - 25px) / 3) / 467) * 117);
	--header-text-height: calc(1.3125rem + 0.125rem);
	--header-spacer-height: var(--space-5--rem);

	position: sticky;
	top: calc(-1 * (var(--header-spacer-height) + var(--header-text-height) + var(--header-logo-height)));
	z-index: 100;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header-container {
		--header-logo-height: calc((((100vw - 40px) / 3) / 467) * 117);
		--header-text-height: calc(1.875rem + var(--space-5--rem));

		top: calc(-1 * (var(--header-spacer-height) + var(--header-text-height) + var(--header-logo-height)));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-header-container {
		--header-spacer-height: var(--space-10--rem);
		--header-text-height: calc(3.125rem + var(--space-10--rem));

		top: calc(-1 * (var(--header-spacer-height) + var(--header-text-height) + var(--header-logo-height)));
	}
}

[data-minimal-header] .site-header-container {
	--header-text-height: 0px;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	[data-header-show-only-the-menu-from-1024] .site-header-container {
		--header-logo-height: 0px;
		--header-spacer-height: 0px;
		--header-text-height: 0px;
	}
}

.site-header {
	color: var(--color-foreground);
	padding-block: var(--space-5--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-header {
		padding-block: var(--space-10--rem);
	}
}

.site-header__home-link {
	display: block;
	margin: 0;
	padding: 0;
	text-decoration: none;
}

.site-header__home-link:hover {
	color: currentColor;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	[data-header-show-only-the-menu-from-1024] .site-header__home-link {
		display: none;
	}
}

.site-header__title-and-date-and-location {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(6, minmax(0, 1fr));
	margin-block-end: 0.125rem;
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__title-and-date-and-location {
		column-gap: var(--space-10--px);
	}
}

[data-minimal-header] .site-header__title-and-date-and-location {
	display: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	[data-header-show-only-the-menu-from-1024] .site-header__title-and-date-and-location {
		display: none;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-header__title-and-date-and-location {
		grid-template-columns: repeat(18, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__title-and-date-and-location {
		grid-template-columns: repeat(6, minmax(0, 1fr));
		margin-block-end: var(--space-5--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__title-and-date-and-location {
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-header__title-and-date-and-location {
		margin-block-end: var(--space-10--rem);
	}
}

.site-header__title {
	display: none;
	grid-row: 1;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-header__title {
		display: block;
		grid-column: 1 / span 6;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__title {
		grid-column: 1 / span 2;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__title {
		grid-column: 1 / span 3;
	}
}

.site-header__date {
	grid-row: 1;
	grid-column: 1 / span 6;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-header__date {
		grid-column: 7 / span 12;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__date {
		grid-column: 3 / span 4;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__date {
		grid-column: 4 / span 6;
	}
}

.site-header__location {
	grid-row: 1;
	grid-column: 1 / span 6;
	text-align: right;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-header__location {
		grid-column: 7 / span 12;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__location {
		grid-column: 3 / span 4;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__location {
		grid-column: 4 / span 6;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 23rem) {
	.site-header__date [data-date-format="short"] {
		display: none;
	}
}

.site-header__date [data-date-format="default"] {
	display: none;
}

/* from-xsmall,min-width-560px */
@media (width >= 23rem) {
	.site-header__date [data-date-format="default"] {
		display: inline;
	}
}

.site-header__logo {
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(3, minmax(0, 1fr));
	min-width: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__logo {
		column-gap: var(--space-10--px);
	}
}

.site-header__logo svg {
	width: 100%;
	min-width: 0;
}

.site-header__primary-navigation__list {
	display: grid;
	row-gap: var(--space-5--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(2, minmax(0, 1fr));
	list-style-type: none;
	margin: 0;
	margin-block-start: var(--space-5--rem);
	padding: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__primary-navigation__list {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-header__primary-navigation__list {
		margin-block-start: var(--space-10--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__primary-navigation__list {
		column-gap: var(--space-10--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	[data-header-show-only-the-menu-from-1024] .site-header__primary-navigation__list {
		margin-block-start: 0;
	}
}

.site-header__primary-navigation__item {
	grid-column: span 1;
	line-height: 0;
	position: relative;
	min-width: 0;
}

.site-header__primary-navigation__item[data-is-anchor-navigation] {
	grid-column: span 2;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__primary-navigation__item[data-is-anchor-navigation] {
		grid-column: span 1;
	}
}

.site-header__primary-navigation__item .button {
	text-align: center;
	width: 100%;
}

.site-header__sub-navigation {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
}

.site-header__sub-navigation__list {
	backdrop-filter: blur(10px);
	background-color: var(--color-sub-navigation-background);
	border-radius: 15px;
	box-sizing: border-box;
	display: block;
	list-style-type: none;
	margin: 0;
	margin-block-start: var(--space-5--px);
	padding: var(--space-10--px);
	width: 100%;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-header__sub-navigation__list {
		border-radius: 30px;
		margin-block-start: var(--space-10--px);
		padding: var(--space-20--px);
	}
}

.site-header__sub-navigation__item {
	line-height: 0;
}

.site-header__sub-navigation__item:not(:first-child) {
	margin-block-start: 2px;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__sub-navigation__item:not(:first-child) {
		margin-block-start: 0;
	}
}

.site-header__sub-navigation__item__link {
	color: var(--color-sub-navigation-foreground);
	text-decoration: 2px underline transparent;
	transition:
		color 96ms linear,
		text-decoration-color 96ms linear;
}

.site-header__sub-navigation__item__link:hover {
	color: var(--color-sub-navigation-foreground-hover);
}

[data-is-active] > .site-header__sub-navigation__item__link {
	color: var(--color-sub-navigation-foreground-active);
	text-decoration-color: currentColor;
}

.site-header__anchor-navigation__list {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.site-header__anchor-navigation__item {
	line-height: 0;
}

.site-header__anchor-navigation__item__link {
	color: var(--color-anchor-navigation-item-foreground);
	display: grid;
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(6, minmax(0, 1fr));
	text-decoration: none;
	transition: color 96ms linear;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-header__anchor-navigation__item__link {
		grid-template-columns: repeat(18, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__anchor-navigation__item__link {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__anchor-navigation__item__link {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.site-header__anchor-navigation__item__link:hover {
	color: var(--color-anchor-navigation-item-foreground-hover);
}

[data-is-active] .site-header__anchor-navigation__item__link {
	color: var(--color-anchor-navigation-item-foreground-active);
}

.site-header__anchor-navigation__counter {
	font-variant-numeric: tabular-nums;
	grid-column: 1 / span 1;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-header__anchor-navigation__counter {
		grid-column: 1 / span 2;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__anchor-navigation__counter {
		grid-column: 1 / span 1;
	}
}

.site-header__anchor-navigation__title {
	grid-column: 2 / span 5;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.site-header__anchor-navigation__title {
		grid-column: 3 / span 16;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header__anchor-navigation__title {
		grid-column: 2 / span 5;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-header__anchor-navigation__title {
		grid-column: 2 / span 2;
	}
}
