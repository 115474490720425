.button {
	-mrh-resets: button;
	backdrop-filter: blur(10px);
	background-color: var(--color-button-background);
	border-radius: var(--border-radius-9999);
	color: var(--color-button-foreground);
	padding-inline: var(--space-20--px);
	padding-block: 2.5px 3px;
	transition:
		color 96ms linear,
		background-color 96ms linear;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.button {
		padding-block: 4px 4.5px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.button {
		padding-inline: var(--space-30--px);
		padding-block: 9.5px 10px;
	}
}

.button:hover {
	background-color: var(--color-button-background-hover);
	color: var(--color-button-foreground-hover);
}

[data-is-active] .button,
details[open] .button {
	background-color: var(--color-button-background-active);
	color: var(--color-button-foreground-active);
}

summary.button::marker {
	display: none;
}

summary.button::-webkit-details-marker {
	display: none;
}
