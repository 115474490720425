.video-embed {
	position: relative;
}

.video-embed__title {
	margin-block-end: var(--space-10--rem);
}

.video-embed__video-wrapper {
	aspect-ratio: 16 / 9;
	display: flex;
	width: 100%;
}

.video-embed__video-wrapper iframe {
	border-radius: 15px;
	width: 100%;
	height: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.video-embed__video-wrapper iframe {
		border-radius: 20px;
	}
}
