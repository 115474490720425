.artist-slideshow {
	--cursor-display-indicator: none;
	--cursor-thumbnail--left: 0px;
	--cursor-thumbnail--top: 0px;
	--slide-height: calc(100vh - 1.9375rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.artist-slideshow {
		--slide-height: calc(100vh - 2.0625rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.artist-slideshow {
		--slide-height: calc(100vh - 2.375rem);
	}
}

@supports (top: 1svh) {
	.artist-slideshow {
		--slide-height: calc(100svh - 1.9375rem);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		.artist-slideshow {
			--slide-height: calc(100svh - 2.0625rem);
		}
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		.artist-slideshow {
			--slide-height: calc(100svh - 2.375rem);
		}
	}
}

.artist-slideshow {
	display: block;
	position: relative;
}

.artist-slideshow__carousel {
	cursor: pointer;
	display: grid;
	margin: 0;
	padding: 0;
	height: max-content;
}

.artist-slideshow__carousel[data-number-of-slides="1"] {
	cursor: default;
}

.artist-slideshow__item {
	display: block;
	grid-row: 1;
	grid-column: 1;
	opacity: 0;
	transition: opacity 96ms linear;
	width: 100%;
	z-index: 0;
}

.artist-slideshow__item[data-slide="current"] {
	opacity: 1;
	z-index: 1;
}

.artist-slideshow__item[data-slide="next"],
.artist-slideshow__item[data-slide="previous"] {
	pointer-events: none;
	z-index: 2;
}

.artist-slideshow__image-container,
.artist-slideshow__video-container {
	display: block;
	margin: 0;
	pointer-events: none;
	user-select: none;
}

.artist-slideshow__image,
.artist-slideshow__video {
	margin: 0;
	object-fit: cover;
	object-position: center center;
	padding: 0;
	position: relative;
	width: 100vw;
	height: var(--slide-height);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.artist-slideshow__image[data-is-portrait],
	.artist-slideshow__video[data-is-portrait] {
		object-fit: contain;
	}
}

.artist-slideshow__counter {
	display: block;
	font-variant-numeric: tabular-nums;
	opacity: 1;
	pointer-events: none;
	position: absolute;
	right: var(--space-10--px);
	bottom: var(--space-10--rem);
	user-select: none;
	z-index: 10;
}

@media (hover: hover) {
	.artist-slideshow__counter {
		display: var(--cursor-display-indicator);
		opacity: 0;
		padding-inline: var(--space-20--px);
		position: fixed;
		top: min(calc(var(--cursor-thumbnail--top) + 20px), (var(--slide-height) - 30px));
		right: auto;
		bottom: auto;
		left: min(calc(var(--cursor-thumbnail--left) + 4px), (100vw - 48px));
		transition: opacity 96ms linear;
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		.artist-slideshow__counter {
			left: min(calc(var(--cursor-thumbnail--left) + 4px), (100vw - 56px));
		}
	}

	.artist-slideshow:hover .artist-slideshow__counter {
		opacity: 1;
	}
}
