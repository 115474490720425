.teaser-b {
	aspect-ratio: 1 / 1;
	display: flex;
}

.teaser-b__image-container {
	width: 100%;
	height: 100%;
}

.teaser-b__image {
	background-color: var(--color-sponsor-background);
	border-radius: 15px;
	object-fit: cover;
	transition: background-color 96ms linear;
	width: 100%;
	height: 100%;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-b__image {
		border-radius: 20px;
	}
}

.teaser-b__image:hover {
	background-color: var(--color-sponsor-background-hover);
}

.teaser-b__image[data-is-placeholder] {
	background-color: var(--color-sponsor-placeholder-background);
	box-sizing: border-box;
	color: var(--color-sponsor-placeholder-foreground);
	display: grid;
	padding: 5px;
	place-content: center;
}
