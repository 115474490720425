.accordion-tickets {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
}

.accordion-tickets:not(:first-child) {
	margin-block-start: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.accordion-tickets {
		margin-block-start: 0;
	}
}

.accordion-tickets__item {
	color: var(--color-accordion-not-for-sale);
}

.accordion-tickets__item[data-status="available"] {
	color: var(--color-accordion);
}

.accordion-tickets__item:not(:last-child) {
	margin-block-end: var(--space-10--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-tickets__item:not(:last-child) {
		margin-block-end: var(--space-5--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.accordion-tickets__item:not(:last-child) {
		margin-block-end: 0;
	}
}

.accordion-tickets__heading {
	margin: 0;
	padding: 0;
}

.accordion-tickets__trigger {
	-mrh-resets: button;
	color: var(--color-accordion-not-for-sale);
	display: block;
	text-align: left;
	transition: color 96ms linear;
	width: 100%;
}

html:not(.no-js) .accordion-tickets__trigger:hover {
	color: var(--color-accordion-not-for-sale-hover);
	cursor: pointer;
}

html:not(.no-js) [data-status="available"] .accordion-tickets__trigger {
	color: var(--color-accordion);
}

html:not(.no-js) [data-status="available"] .accordion-tickets__trigger:hover {
	color: var(--color-accordion-hover);
}

.accordion-tickets__item__title {
	display: grid;
	row-gap: var(--space-5--rem);
	column-gap: var(--space-5--px);
	grid-template-columns: repeat(6, minmax(0, 1fr));
	width: 100%;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion-tickets__item__title {
		grid-template-columns: repeat(18, minmax(0, 1fr));
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-tickets__item__title {
		column-gap: var(--space-10--px);
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-tickets__item__title {
		grid-template-columns: repeat(9, minmax(0, 1fr));
	}
}

html.no-js .accordion-tickets__item__title {
	cursor: text;
	pointer-events: auto;
	user-select: text;
}

.accordion-tickets__item__title__counter {
	font-variant-numeric: tabular-nums;
	grid-column: 1 / span 1;
	text-align: left;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion-tickets__item__title__counter {
		grid-column: 1 / span 2;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-tickets__item__title__counter {
		grid-column: 1 / span 1;
	}
}

.accordion-tickets__item__title__text {
	grid-column: 2 / span 5;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion-tickets__item__title__text {
		grid-column: 3 / span 16;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-tickets__item__title__text {
		grid-column: 2 / span 5;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-tickets__item__title__text {
		grid-column: 2 / span 2;
	}
}

.accordion-tickets__item__title__price {
	grid-column: 2 / span 5;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion-tickets__item__title__price {
		grid-column: 3 / span 16;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-tickets__item__title__price {
		grid-column: 2 / span 5;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-tickets__item__title__price {
		grid-column: 4 / span 3;
	}
}

.accordion-tickets__item__title__status-label {
	grid-column: 2 / span 5;
	min-width: 0;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.accordion-tickets__item__title__status-label {
		grid-column: 3 / span 16;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.accordion-tickets__item__title__status-label {
		grid-column: 2 / span 5;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.accordion-tickets__item__title__status-label {
		grid-column: 7 / span 3;
	}
}

.accordion-tickets__trigger[data-status="available"] .accordion-tickets__item__title__status-label {
	text-decoration: underline;
}

.accordion-tickets__panel {
	padding-block-end: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.accordion-tickets__panel {
		padding-block-end: var(--space-50--rem);
	}
}

html:not(.no-js, .js-loading-error) .accordion-tickets__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion-tickets__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.accordion-tickets__panel__inner > :first-child {
	margin-block-start: 0;
}

.accordion-tickets__panel__inner > :last-child {
	margin-block-end: 0;
}
